import React from "react";
import { useForm } from "react-hook-form";
import { useForm as useFormspree } from "@formspree/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

function mergeErrors(formspreeErrors, clientErrors = {}) {
  return {
    ...formspreeErrors.reduce(
      (acc, cur) => ({
        [cur.field]: { message: (cur.field ? "This " : "") + cur.message },
        ...acc,
      }),
      {}
    ),
    ...clientErrors,
  };
}

export default function Contact() {
  const [serverState, sendToFormspree] = useFormspree("xrgvlnyz");
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors: clientErrors },
  } = useForm({ mode: "all" });

  const errors = mergeErrors(serverState.errors, clientErrors);

  return (
    <>
      <div className="mx-auto max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 pb-8 lg:gap-8 content-center">
        <div className="pt-16 pb-24 sm:pt-24 sm:pb-32 lg:pt-32">
          <div className="px-6 lg:px-8">
            <h1 className="max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-2 xl:col-auto">
              {t("contact.title")}
            </h1>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              {t("contact.subtitle")}
            </p>
            <form
              onSubmit={handleSubmit(sendToFormspree)}
              className="mx-auto mt-10 max-w-xl sm:mt-10"
            >
              <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("contact.form.firstName")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      {...register("firstName", {
                        required: t("contact.form.errorMessage"),
                      })}
                      className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.firstName && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("contact.form.lastName")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      {...register("lastName", {
                        required: t("contact.form.errorMessage"),
                      })}
                      className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.lastName && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("contact.form.company")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      {...register("company", {
                        required: t("contact.form.errorMessage"),
                      })}
                      className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.company && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {errors.company.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("contact.form.email")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      {...register("email", {
                        required: t("contact.form.errorMessage"),
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                      className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.email && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("contact.form.message")}
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      {...register("message", {
                        required: t("contact.form.errorMessage"),
                      })}
                      className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.message && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {errors.message.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {serverState.succeeded ? (
                <div className="rounded-md bg-green-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-green-800">
                        {t("contact.form.success")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-10">
                  <button
                    type="submit"
                    disabled={
                      (clientErrors && Object.keys(clientErrors).length) ||
                      serverState.submitting
                    }
                    className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t("contact.form.action")}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="pt-16 pb-24 sm:pt-24 sm:pb-32 lg:pt-32">
            <div className="overflow-hidden rounded-lg bg-white ">
              <div className="p-4 rounded-lg ">
                <h2 className="text-xl font-bold mb-4">
                  {t("contact.info.text")}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
