import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const MineralsList = () => {
  const { t } = useTranslation();
  const minerals = [
    {
      name: t("solutions.minerals.atomic.lithium.name"),
      initials: "Li",
      details: t("solutions.minerals.atomic.lithium.text"),
      atomicNumber: 7,
      bgColor: "#919191",
    },
    // {
    //   name: t("solutions.minerals.atomic.manganese.name"),
    //   initials: "Mn",
    //   details: t("solutions.minerals.atomic.manganese.text"),
    //   atomicNumber: 25,
    //   bgColor: "#FFB5B8",
    // },
    {
      name: t("solutions.minerals.atomic.iron.name"),
      initials: "Fe",
      details: t("solutions.minerals.atomic.iron.text"),
      atomicNumber: 26,
      bgColor: "#D8BFD8",
    },
    {
      name: t("solutions.minerals.atomic.cobalt.name"),
      initials: "Co",
      details: t("solutions.minerals.atomic.cobalt.text"),
      atomicNumber: 27,
      bgColor: "#00FFFF",
    },
    {
      name: t("solutions.minerals.atomic.nickel.name"),
      initials: "Ni",
      details: t("solutions.minerals.atomic.nickel.text"),
      atomicNumber: 28,
      bgColor: "#C2B280",
    },
    {
      name: t("solutions.minerals.atomic.copper.name"),
      initials: "Cu",
      details: t("solutions.minerals.atomic.copper.text"),
      atomicNumber: 29,
      bgColor: "#B87333",
    },
    {
      name: t("solutions.minerals.atomic.aluminium.name"),
      initials: "Al",
      details: t("solutions.minerals.atomic.aluminium.text"),
      atomicNumber: 13,
      bgColor: "#C0C0C0",
    },
    {
      name: t("solutions.minerals.atomic.carbon.name"),
      initials: "C",
      details: t("solutions.minerals.atomic.carbon.text"),
      atomicNumber: 6,
      bgColor: "#696969",
    },
    {
      name: t("solutions.minerals.atomic.electrolyte.name"),
      initials: "LiPF6",
      details: t("solutions.minerals.atomic.electrolyte.text"),
      atomicNumber: "",
      bgColor: "#9599a5",
    },
  ];
  const [selectedMineral, setSelectedMineral] = useState();

  const handlemineralClick = (mineral) => {
    setSelectedMineral(mineral);
  };

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
          {t("solutions.minerals.title")}
        </h2>
      </div>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
        {minerals.map((mineral) => {
          const selected = mineral === selectedMineral;
          return (
            <li
              key={mineral.name}
              className={`col-span-1 flex rounded-md shadow-sm cursor-pointer ${
                selected ? "bg-gray-100" : ""
              }`}
              onClick={() => handlemineralClick(mineral)}
            >
              <div
                style={{
                  backgroundColor: mineral.bgColor,
                }}
                className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-bold text-white"
              >
                {mineral.initials}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div
                    className={`font-bold ${
                      selected
                        ? "text-indigo-600"
                        : "text-gray-900 hover:text-gray-600"
                    }`}
                  >
                    {mineral.name}
                  </div>
                  {mineral.atomicNumber && (
                    <p className="text-gray-500">
                      {t("solutions.minerals.atomic.title", {
                        atomicNumber: mineral.atomicNumber,
                      })}
                    </p>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      {!selectedMineral ? (
        <div className="overflow-hidden mx-auto max-w-3xl rounded-lg bg-white shadow px-6 lg:px-8 mt-10">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-xl text-center">
              {t("solutions.minerals.action")}
            </h2>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: selectedMineral.bgColor,
          }}
          className="overflow-hidden rounded-lg shadow-md mx-auto max-w-3xl px-6 lg:px-8 mt-10 text-white"
        >
          <div className="px-4 py-5 sm:p-6">
            <div className="flex">
              <div className="mr-4 flex-shrink-0 self-center">
                <div className="font-bold text-5xl">
                  {selectedMineral.initials}
                </div>
              </div>
              <div>
                <h4 className="text-lg font-bold">{selectedMineral.name}</h4>
                <p className="mt-1">{selectedMineral.details}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MineralsList;
