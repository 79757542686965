import BatteryChart from "./battery-chart";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Relationships from "./relationships";

export default function Home() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const stats = [
    {
      id: 1,
      title: t("home.info.one.subtitle"),
      subtitle: t("home.info.one.text"),
      value: t("home.info.one.title"),
    },
    {
      id: 2,
      title: t("home.info.two.subtitle"),
      subtitle: t("home.info.two.text"),
      value: t("home.info.two.title"),
    },
    {
      id: 3,
      title: t("home.info.three.subtitle"),
      subtitle: t("home.info.three.text"),
      value: t("home.info.three.title"),
    },
  ];

  return (
    <div className="bg-white">
      <main>
        <div className="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
          <div className="mx-auto max-w-7xl px-6 lg:px-0">
            <div className="lg:grid lg:grid-cols-2 pb-8 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                <div className="lg:py-24">
                  <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">{t("home.hero.title.one")}</span>
                    <span className="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">
                      {t("home.hero.title.two")}
                    </span>
                  </h1>
                  <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                    {t("home.hero.subtitle")}
                  </p>
                </div>
              </div>
              <div className="mt-12 mb-16 sm:-mb-48 lg:relative lg:m-0">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={`/assets/hero-image2-${lang}.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" relative overflow bg-white pb-14 sm:pb-24 pt-14">
          <div className="mx-auto max-w-7xl px-6 lg:px-0">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center">
              <div className="lg:pt-4 lg:pr-4">
                <div className="lg:max-w-lg">
                  <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                    {t("home.main.title")}
                  </h1>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t("home.main.subtitle")}
                  </p>
                  <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                    {t("home.main.text")}
                  </p>
                </div>
              </div>
              <ParentSize>
                {({ width, height }) => {
                  return <BatteryChart width={width} height={600} />;
                }}
              </ParentSize>
              <div className="text-left font-light mb-4">
                Source: www.qnovo.com, Bloomberg, UniCredit Research
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-900 mx-auto max-w-7xl  rounded-lg py-24 sm:py-32">
          <div className="px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3">
              {stats.map((stat) => (
                <div
                  key={stat.id}
                  className="mx-auto flex max-w-xs flex-col gap-y-4"
                >
                  <dt className="text-2xl font-extrabold leading-5 text-white">
                    {stat.title}
                  </dt>
                  <dt className="text-base leading-7 text-gray-400">
                    {stat.subtitle}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <div className="relative isolate overflow-hidden bg-gradient-to-b pt-14">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-10 lg:px-0">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                {t("home.solution.title")}
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                  {t("home.solution.text.one")}
                </p>
                <p className="text-lg leading-8 text-gray-600 mt-4">
                  {t("home.solution.text.two")}
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    to="/contact"
                  >
                    {t("home.solution.action_one")}
                  </Link>
                  <Link
                    className="text-sm font-semibold leading-6 text-gray-900"
                    to="/solutions"
                  >
                    {t("home.solution.action_two")}{" "}
                    <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
              <img
                src={`/assets/solution2-${lang}.png`}
                alt=""
                className="mt-10  w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
              />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>
        {/* Relationship Section */}

        <Relationships />

        {/* CTA Section */}

        <div className="py-12 sm:py-24 mx-auto max-w-7xl px-6 lg:px-8">
          <div className=" max-w-2xl px-6 lg:px-8 lg:max-w-none py-24 sm:py-32 lg:flex lg:items-center lg:justify-between  bg-indigo-100 rounded-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">{t("home.cta.title")}</span>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="ml-3 inline-flex">
                <Link
                  to="/solutions"
                  className="rounded-md bg-indigo-600 px-5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("home.cta.action")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
