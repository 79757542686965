import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FlowChart from "./flow-chart";
import MineralsList from "./minerals-list";

const Solutions = () => {
  const { t } = useTranslation();

  const stats = [
    {
      id: 1,
      subtitle: t("solutions.info.one.text"),
      value: t("solutions.info.one.title"),
    },
    {
      id: 2,
      subtitle: t("solutions.info.two.text"),
      value: t("solutions.info.two.title"),
    },
    {
      id: 3,
      subtitle: t("solutions.info.three.text"),
      value: t("solutions.info.three.title"),
    },
    {
      id: 4,
      subtitle: t("solutions.info.four.text"),
      value: t("solutions.info.four.title"),
    },
  ];
  return (
    <>
      <div className="overflow-hidden pb-14 sm:pb-24 pt-14">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center">
            <div className="lg:pt-4 lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {t("solutions.hero.title")}
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {t("solutions.hero.subtitle")}
                </p>
                <div className="mt-8">
                  <Link
                    to="/contact"
                    className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t("solutions.hero.action")}
                  </Link>
                </div>
              </div>
            </div>
            <img
              src="/assets/battery1.jpg"
              alt="Battery"
              className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-xl md:h-2/3"
              width={2432}
              height={1442}
            />
          </div>
          <div className="my-8">
            <MineralsList />
          </div>
          <div>
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
              {t("solutions.process.title")}
            </h2>
          </div>
          <div style={{ height: 500 }}>
            <FlowChart />
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-lg text-center sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                  <dt className="text-sm font-semibold leading-6">
                    {stat.title}
                  </dt>
                  <dt className="text-sm mt-4 leading-6 ">{stat.subtitle}</dt>
                  <dd className="order-first text-4xl font-bold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <div className="pt-12 sm:pt-24 mx-auto max-w-7xl px-6 lg:px-8">
          <div className=" max-w-2xl  px-6 lg:px-8 lg:max-w-none py-24 sm:py-32 lg:flex lg:items-center lg:justify-between  bg-indigo-100 rounded-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">{t("solutions.cta.title")}</span>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="ml-3 inline-flex">
                <Link
                  to="/contact"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("solutions.cta.action")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solutions;
