import { useTranslation } from "react-i18next";

export default function Team() {
  const { t } = useTranslation();

  const team = [
    {
      name: "Fred Rostami",
      role: t("team.titles.ceo"),
      imageUrl: "./assets/fred-rostami.jpg",
      linkedinUrl: "https://www.linkedin.com/in/frederikrostami/",
    },
    {
      name: "Melina Roshanfar Ph.D",
      role: t("team.titles.headRD"),
      imageUrl: "./assets/melina-roshanfar.png",
      linkedinUrl: "https://www.linkedin.com/in/melina-roshanfar/",
    },
    {
      name: "Emna Azek Ph.D",
      role: t("team.titles.chemAnalyst"),
      imageUrl: "./assets/emna-azek.png",
      linkedinUrl: "https://www.linkedin.com/in/emna-azek-phd-3001a019b/",
    },
    {
      name: "Ehsan Mirzaee Ph.D, EIT",
      role: t("team.titles.principalResearch"),
      imageUrl: "./assets/ehsan-mirzaee.png",
      linkedinUrl:
        "https://www.linkedin.com/in/ehsan-mirzaee-phd-eit-04b558168/",
    },
  ];

  const advisors = [
    {
      name: "Majid Sartaj Ph.D",
      role: t("team.titles.advisor"),
      imageUrl: "./assets/majid-sartaj.png",
      linkedinUrl: "https://www.linkedin.com/in/majid-sartaj-58a2b230/",
      websiteUrl: "https://engineering.uottawa.ca/people/sartaj-majid",
    },
    {
      name: "Jeff Gehlhoff",
      role: t("team.titles.advisor"),
      imageUrl: "./assets/jeff-gehlhoff.png",
      linkedinUrl: "https://www.linkedin.com/in/jgehlhoff",
    },
    {
      name: "Lina Emilsson",
      role: t("team.titles.advisor"),
      imageUrl: "./assets/lina-emilsson.png",
      linkedinUrl: "https://www.linkedin.com/in/lina-emilsson-53b39522/",
    },
    // {
    //   name: "Jared Lucas",
    //   role: t("team.titles.advisor"),
    //   imageUrl: "./assets/jared-lucas.png",
    //   linkedinUrl: "https://www.linkedin.com/in/jared-ezra-lucas/",
    // },
    {
      name: "Kory Li",
      role: t("team.titles.advisor"),
      imageUrl: "./assets/kory-li.png",
      linkedinUrl: "https://www.linkedin.com/in/kory-li-2a289428/",
    },
  ];

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-2">
        <div className="max-w-xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t("team.title")}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t("team.subtitle")}
          </p>
        </div>
        <div>
          <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {team.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {person.name}
                    </h3>
                    <p className="text-sm font-semibold leading-6 text-indigo-600">
                      {person.role}
                    </p>
                    <li>
                      <a
                        href={person.linkedinUrl}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <h3 className="mt-20 mb-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-xl">
            {t("team.advisory")}
          </h3>
          <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {advisors.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {person.name}
                    </h3>
                    <p className="text-sm font-semibold leading-6 text-indigo-600">
                      {person.role}
                    </p>
                    <div className="flex">
                      <li>
                        <a
                          href={person.linkedinUrl}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">LinkedIn</span>
                          <svg
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>

                      {person.websiteUrl && (
                        <li className="ml-2">
                          <a
                            href={person.websiteUrl}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Website</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                              />
                            </svg>
                          </a>
                        </li>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
