import React from "react";
import { BarStack } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { LegendOrdinal } from "@visx/legend";
import { useTranslation } from "react-i18next";

const gray = "#d3d3d3";
const background = "#eaedff";
const defaultMargin = { top: 60, right: 0, bottom: 60, left: 280 };

const legendStyles = {
  display: "flex",
  minWidth: 230,
  backgroundColor: "white",
  borderRadius: "10px",
  color: "#282828",
  fontSize: 12,
  position: "absolute",
  top: 10,
  left: 5,
  boxShadow: "2px 2px 5px #ccd3de",
};

export default function BatteryChart({
  width,
  height,
  event = false,
  margin = defaultMargin,
}) {
  const { t } = useTranslation();

  const data = [
    {
      [t("batteryChart.legend.cathode")]: 40,
      [t("batteryChart.legend.anode")]: 15,
      [t("batteryChart.legend.electrolyte")]: 22.5,
      [t("batteryChart.legend.separator")]: 22.5,
      [t("batteryChart.legend.otherMaterials")]: 25,
      [t("batteryChart.legend.labor")]: 10,
      [t("batteryChart.legend.overhead")]: 65,
      [t("batteryChart.legend.profitMargin")]: 10,
    },
  ];

  const keys = Object.keys(data[0]);

  const materialSum = Object.values(data[0]).reduce(
    (acc, value) => acc + value,
    0
  );

  const getLevel = (d) => d.level;

  const levelScale = scaleBand({ domain: [undefined], padding: 0.4 });
  const scoreScale = scaleLinear({
    domain: [0, materialSum],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [
      "#845ec2",
      "#d55db1",
      "#ff6f91",
      "#ff9771",
      "#ffc75f",
      "#f8f872",
      "#c4fcef",
    ],
  });
  const percentageScale = scaleLinear({
    domain: [0, 240],
  });

  // creates the bounds
  const xMin = 0;
  const xMax = width - margin.left - margin.right;
  const yMin = 0;
  const yMax = height - margin.top - margin.bottom;

  scoreScale.range([yMax, yMin]);
  levelScale.range([xMin, xMax]);
  percentageScale.rangeRound([yMax, yMin]);

  if (!width || !height) {
    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={background}
          rx={10}
        />

        <Group top={margin.top} left={margin.left}>
          <BarStack
            data={data}
            keys={keys}
            x={getLevel}
            xScale={levelScale}
            yScale={scoreScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  return (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      height={bar.height}
                      width={bar.width}
                      fill={bar.color}
                      stroke="white"
                    />
                  );
                })
              )
            }
          </BarStack>
        </Group>

        <AxisBottom
          top={yMax + margin.top}
          left={margin.left}
          scale={levelScale}
          hideTicks
          stroke={gray}
          strokeWidth={1}
          label={t("batteryChart.xLabel")}
          labelProps={{
            fill: "#000",
            fontWeight: 700,
            fontSize: 14,
            textAnchor: "middle",
          }}
        />
        <AxisLeft
          top={margin.top}
          left={margin.left}
          scale={percentageScale}
          hideTicks
          numTicks={6}
          tickFormat={(percent) => percent}
          stroke={gray}
          strokeWidth={1}
          label={t("batteryChart.yLabel")}
          labelProps={{
            fill: "#000",
            fontWeight: 700,
            fontSize: 14,
            textAnchor: "middle",
          }}
        />
        <GridRows
          top={margin.top}
          left={margin.left}
          scale={percentageScale}
          width={xMax}
          height={yMax - margin.top - margin.bottom}
          stroke="white"
          strokeOpacity={0.4}
          numTicks={5}
        />
      </svg>

      <LegendOrdinal
        scale={colorScale}
        style={legendStyles}
        direction="column-reverse"
        shape="circle"
        shapeMargin="5px 3px 5px 8px"
      />
    </div>
  );
}
