import React from "react";

function Privacy() {
  return (
    <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div>
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-8 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-2xl lg:text-4xl">
              Privacy Policy for GreenLIB Materials Inc.
            </p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-gray-700 mb-4">
          The Internet is an amazing tool. It has the power to change the way we
          live, and we're starting to see that potential today. With only a few
          mouse-clicks, you can follow the news, look up facts, buy goods and
          services, and communicate with others from around the world. It's
          important to GreenLIB Materials Inc. to help our customers retain
          their privacy when they take advantage of all the Internet has to
          offer.
        </p>
        <p className="text-gray-700 mb-4">
          We believe your business is no one else's. Your privacy is important
          to you and to us. So we'll protect the information you share with us.
          To protect your privacy, GreenLIB Materials Inc. follows different
          principles in accordance with worldwide practices for customer privacy
          and data protection.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li className="font-semibold mb-2">
            We won’t sell or give away your name, mail address, phone number,
            email address or any other information to anyone.
          </li>
          <li className="font-semibold mb-2">
            We’ll use state-of-the-art security measures to protect your
            information from unauthorized users.
          </li>
        </ul>

        <h4 className="text-lg font-semibold mb-4">Notice</h4>
        <p className="text-gray-700 mb-4">
          We will ask you when we need information that personally identifies
          you (personal information) or allows us to contact you. Generally,
          this information is requested when you create a Registration ID on the
          site or when you download free software, enter a contest, order email
          newsletters. We use your Personal Information for four primary
          purposes:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li className="font-semibold mb-2">
            To help send you content most relevant to you.
          </li>
          <li className="font-semibold mb-2">
            To alert you to product upgrades, special offers, updated
            information and other new services from GreenLIB Materials Inc.
          </li>
        </ul>

        <h4 className="text-lg font-semibold mb-4">Access</h4>

        <p className="text-gray-700 mb-4">
          We will provide you with the means to ensure that your personal
          information is correct and current. You may review and update this
          information at any time just by send us an email. You can:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li className="font-semibold mb-2">
            View and edit personal information you have already given us.
          </li>
          <li className="font-semibold mb-2">
            Tell us whether you want us to send you marketing information.
          </li>
          <li className="font-semibold mb-2">
            Sign up for electronic newsletters about our services and products.
          </li>
        </ul>
        <h4 className="text-lg font-semibold mb-4">Security</h4>
        <p className="text-gray-700 mb-4">
          GreenLIB Materials Inc. has taken strong measures to protect the
          security of your personal information and to ensure that your choices
          for its intended use are honored. We take strong precautions to
          protect your data from loss, misuse, unauthorized access or
          disclosure, alteration, or destruction.
        </p>
        <p className="text-gray-700 mb-4">
          GreenLIB Materials Inc. strictly protects the security of your
          personal information and honors your choices for its intended use. We
          carefully protect your data from loss, misuse, unauthorized access or
          disclosure, alteration, or destruction.
        </p>
        <p className="text-gray-700 mb-4">
          Your personal information is never shared outside the company without
          your permission. Inside the company, data is stored in
          password-controlled servers with limited access. Your information may
          be stored and processed in Canada or any other country where GreenLIB
          Materials Inc., its subsidiaries, affiliates or agents are located.
        </p>
        <p className="text-gray-700 mb-4">
          You also have a significant role in protecting your information. No
          one can see or edit your personal information.
        </p>
        <h4 className="text-lg font-semibold mb-4">Notice to parents</h4>
        <p className="text-gray-700 mb-4">
          Parents or guardians: we want to help you guard your children's
          privacy. We encourage you to talk to your children about safe and
          responsible use of their Personal Information while using the
          Internet.
        </p>
        <p className="text-gray-700 mb-4">
          The GreenLIB Materials Inc. site does not publish content that is
          targeted to children. However, if you are concerned about your
          children providing GreenLIB Materials Inc. any personal information
          without your consent, GreenLIB Materials Inc. offers a Kids account.
          It allows parents to give parental consent for the collection, use and
          sharing of children’s (ages 12 and under) personal information online.
        </p>

        <h4 className="text-lg font-semibold mb-4">Enforcement</h4>
        <p className="text-gray-700 mb-4">
          If for some reason you believe GreenLIB Materials Inc. has not adhered
          to these principles, please notify us by email at info@greenlib.co,
          and we will do our best to determine and correct the problem promptly.
          Be certain the words Privacy Policy are in the Subject line.
        </p>

        <h4 className="text-lg font-semibold mb-4">
          What we do with the information you share
        </h4>
        <p className="text-gray-700 mb-4">
          When you join us, you provide us with your contact information,
          including your email address. We use this information to send you
          updates about our solutions and producs, questionnaires to measure
          your satisfaction with our service and announcements about new and
          exciting services that we offer.
        </p>

        <p className="text-gray-700 mb-4">
          GreenLIB Materials Inc. will disclose your personal information,
          without notice, only if required to do so by law or in the good faith
          belief that such action is necessary to: (a) conform to the edicts of
          the law or comply with legal process served on GreenLIB Materials Inc.
          or the site; (b) protect and defend the rights or property of GreenLIB
          Materials Inc. and its family of Websites, and, (c) act in urgent
          circumstances to protect the personal safety of users of GreenLIB
          Materials Inc., its Websites, or the public.
        </p>
        <p>
          If you have any questions or concerns about our Privacy Policy, or if
          you would like to access or correct your personal information, please
          contact us at:
        </p>
        <p>
          GreenLIB Materials Inc. 201 Rue Jacques-le Ber Apt.732 Montréal QC H3E
          0E4
        </p>
        <a href="mailto:info@greenlib.co" target="_new">
          info@greenlib.co
        </a>
        <p>Last updated: March 18, 2023.</p>
      </div>
    </div>
  );
}

export default Privacy;
