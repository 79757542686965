import React from "react";
import ReactFlow, { useNodesState, useEdgesState, Controls } from "reactflow";
import { useTranslation } from "react-i18next";

const initialEdges = [
  {
    id: "el-1",
    source: "1",
    type: "smoothstep",
    target: "3",
    animated: true,
  },
  {
    id: "el-3",
    source: "3",
    type: "smoothstep",
    target: "4",
    animated: true,
  },
  {
    id: "el-4",
    source: "4",
    type: "smoothstep",
    target: "6",
    animated: true,
  },
  {
    id: "el-6",
    source: "6",
    type: "smoothstep",
    target: "7",
    animated: true,
  },
  {
    id: "el-7",
    source: "6",
    type: "smoothstep",
    target: "8",
    animated: true,
  },
];

const FlowChart = () => {
  const { t } = useTranslation();

  const totalWidth = 1000;
  const nodeCount = 6;
  const horizontalSpacing = totalWidth / (nodeCount - 1);
  const verticalSpacing = 100; // Adjust this value as needed

  const initialNodes = [
    {
      id: "1",
      sourcePosition: "right",
      data: { label: t("solutions.process.flow.step.one") },
      type: "input",
      position: { x: 0, y: 80 },
    },
    {
      id: "3",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: t("solutions.process.flow.step.three") },
      position: { x: horizontalSpacing, y: 80 },
    },
    {
      id: "4",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: t("solutions.process.flow.step.four") },
      position: { x: horizontalSpacing * 2, y: 80 },
    },
    {
      id: "6",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: t("solutions.process.flow.step.six") },
      position: { x: horizontalSpacing * 3, y: 80 },
    },
    {
      id: "7",
      targetPosition: "left",
      type: "output",
      data: { label: t("solutions.process.flow.step.seven") },
      position: { x: horizontalSpacing * 4, y: 80 - verticalSpacing },
    },
    {
      id: "8",
      targetPosition: "left",
      type: "output",
      data: { label: t("solutions.process.flow.step.eight") },
      position: { x: horizontalSpacing * 4, y: 80 + verticalSpacing },
    },
  ];

  const [nodes] = useNodesState(initialNodes);
  const [edges] = useEdgesState(initialEdges);

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        fitView
        proOptions={{ hideAttribution: true }}
        className="bg-blue-50 rounded-lg font-bold"
        nodesConnectable={false}
        nodesDraggable={false}
        elementsSelectable={false}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </>
  );
};

export default FlowChart;
