import { useTranslation } from "react-i18next";

export default function Relationships() {
  const { t } = useTranslation();
  return (
    <div className="bg-white py-12 sm:py-24">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t("relationships.title")}
          </h2>
          <div className="mx-auto mt-10 grid grid-cols-4 items-center gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-6">
            <img
              className="col-span-2 max-h-15 w-full object-contain object-center lg:col-span-1"
              src="/assets/foresight.png"
              alt="foresight"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-15 w-full object-contain object-center lg:col-span-1"
              src="/assets/esplanade.png"
              alt="esplanade"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-15 w-full object-contain object-center lg:col-span-1"
              src="/assets/creative-destruction-lab.png"
              alt="creative destruction lab"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-15 w-full object-contain object-center lg:col-span-1"
              src="/assets/greentown-labs.png"
              alt="Greentown labs"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-15 w-full object-contain object-center lg:col-span-1"
              src="/assets/cycle-momentum.png"
              alt="centech"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-15 w-full object-contain object-center lg:col-span-1"
              src="/assets/centech.png"
              alt="centech"
              width={158}
              height={48}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
