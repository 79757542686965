import React, { useState, useEffect } from "react";
import i18n from "../i18n";

const preferredLanguageOption = [
  { value: "English", key: "en" },
  { value: "Français", key: "fr" },
];

export const LanguageSelector = () => {
  const [locale, setLocale] = useState("en");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    lang && setLocale(lang);
  }, [setLocale]);

  const handleLanguageChange = (e) => {
    const selectedLocale = e.target.value === "English" ? "en" : "fr";
    setLocale(selectedLocale);
    i18n.changeLanguage(selectedLocale);
    localStorage.setItem("lang", selectedLocale);
  };

  return (
    <div>
      <select
        onChange={handleLanguageChange}
        className="mt-2 block w-full rounded-md bg-transparent border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        defaultValue="English"
        value={locale === "en" ? "English" : "Français"}
      >
        {preferredLanguageOption.map((lang) => {
          return <option key={lang.key}>{lang.value}</option>;
        })}
      </select>
    </div>
  );
};
